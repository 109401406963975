<style scoped>
@media print {
    @page {
        size: A5 landscape;
        margin: 5mm 5mm 5mm 5mm;
    }

    .page-break {
        page-break-before: always;
    }

    .footer,
    #non-printable {
        display: none !important;
    }

    #printable {
        display: block;
    }

    body {
        font-size: 110%;
    }

    table, td, th {
        font-size: 110%;
    }
}

.page-footer {
    position: fixed;
    bottom: 0mm;
    width: 100%;
}

.page {
    page-break-after: always;
}

table {
    background-color: white !important;
    color: black !important;
}

table tr {
    font-family: "Courier";
    line-height: normal;
    font-size: 11px;
}

.text-bold {
    font-weight: bold;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}
</style>

<template>
    <div>
        <table width="100%" v-for="(data, index) in datas" :key="index">
            <thead>
                <tr>
                    <td>
                        <table width="100%" style="text-align: center; font-size: 14px;">
                            <tr>
                                <td><strong>PROFORMA INVOICE</strong></td>
                            </tr>
                            <tr>
                                <td><strong>{{ data.company_name }}</strong></td>
                            </tr>
                        </table>

                        <br />
                        <table width="100%">
                            <tr>
                                <td>Alamat</td>
                                <td>:</td>
                                <td colspan="4">{{ data.warehouse_name }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td colspan="4">{{ data.warehouse_address }}</td>
                            </tr>
                            <tr>
                                <td>N.P.W.P</td>
                                <td>:</td>
                                <td>01.604.506.4-007.000</td>
                                <td>No.DO / No.SO</td>
                                <td>:</td>
                                <td style="white-space: nowrap;">{{ data.delivery_order_code }} / {{ data.sales_order_code }}</td>
                            </tr>
                            <tr>
                                <td>No.Pengukuhan PKP</td>
                                <td>:</td>
                                <td>01.604.506.4-007.000</td>
                                <td>Tgl. Transaksi</td>
                                <td>:</td>
                                <td>{{ data.invoice_proforma_date }}</td>
                            </tr>
                            <tr>
                                <td>Tgl. Pengukuhan</td>
                                <td>:</td>
                                <td>09-04-2007</td>
                                <td>Syarat Pembayaran</td>
                                <td>:</td>
                                <td>{{ data.payment_term_name }} / {{ data.sales_order_date }}</td>
                            </tr>
                            <tr>
                                <td>Vendor Transportir</td>
                                <td>:</td>
                                <td>-</td>
                                <td>Tgl. Kadaluarsa</td>
                                <td>:</td>
                                <td>{{ data.invoice_proforma_due_date }}</td>
                            </tr>
                            <tr>
                                <td>Vehicle No.</td>
                                <td>:</td>
                                <td>{{ data.vehicle_number }}</td>
                                <td>PO No.</td>
                                <td>:</td>
                                <td></td>
                            </tr>

                            <!-- Customer and Salesman Information -->
                            <tr>
                                <td colspan="3" rowspan="3">
                                    <br/>
                                    Kepada Yth: {{ data.customer_code }}
                                    <br/>
                                    {{ data.customer_name }}
                                    <br/>
                                    {{ data.customer_address }}
                                    <br/>
                                    NPWP : {{ data.customer_npwp }}
                                </td>
                                <td>Kode/ Salesman</td>
                                <td>:</td>
                                <td>{{ data.sales_code }} / {{ data.sales_name }}</td>
                            </tr>
                            <tr>
                                <td>Ref. No.</td>
                                <td>:</td>
                                <td>{{ data.so_reference_code }}</td>
                            </tr>
                            <tr>
                                <td>Print Counter / datetime</td>
                                <td>:</td>
                                <td>{{ data.print_counter }} / {{ printDatetime }}</td>
                            </tr>
                        </table>
                        <br>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <table width="100%">
                            <thead>
                                <tr style="border-top: 2px dashed; border-bottom: 2px dashed">
                                    <td class="text-left text-bold" style="width: 5px;">NO</td>
                                    <td class="text-left text-bold">Item</td>
                                    <td class="text-left text-bold">Item Name</td>
                                    <td class="text-left text-bold">Qty</td>
                                    <td class="text-right text-bold">UOM</td>
                                    <td class="text-right text-bold">Price</td>
                                </tr>
                            </thead>
                            <br>
                            <tbody>
                                <tr v-for="(line, indexLine) in data.lines" :key="indexLine">
                                    <td class="text-left">{{ indexLine + 1 }}</td>
                                    <td class="text-left">{{ line.sku_code }}</td>
                                    <td class="text-left">{{ line.item_name }}</td>
                                    <td class="text-left">{{ line.qty }}</td>
                                    <td class="text-right">{{ line.item_unit }}</td>
                                    <td class="text-right">{{ formatNumber(line.total) }}</td>
                                </tr>
                                <br>
                                <tr style="border-top: 2px dashed">
                                    <td colspan="5" class="text-right text-bold">Total Price</td>
                                    <td class="text-right text-bold">{{ formatNumber(data.total_price) }}</td>
                                </tr>
                                <tr style="border-top: 2px dashed">
                                    <td>Message :</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                        <table width="100%" style="page-break-inside: avoid">
                            <tr>
                                <td class="text-center text-bold">
                                    {{ data.invoice_proforma_date }},
                                    <br/>Yang Menyerahkan
                                </td>
                                <td class="text-center text-bold">Ekspedisi</td>
                                <td class="text-center text-bold">
                                    Tanggal Diterima : .&nbsp;.&nbsp;.&nbsp;.&nbsp;.
                                    <br/>
                                    Yang Menerima
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td class="text-center text-bold">
                                    (...............)
                                    <br />
                                    Nama Jelas
                                </td>
                                <td class="text-center text-bold">
                                    (...............)
                                    <br />
                                    Nama Jelas
                                </td>
                                <td class="text-center text-bold">
                                    (...............)
                                    <br />
                                    Nama Jelas dan Cap
                                </td>
                            </tr>
                        </table>
                        <table style="page-break-after: always;">
                            <tr>
                                <td>
                                    <div>Pemberitahuan Penting:</div>
                                    <div>
                                        1. Surat Jalan ini bukan merupakan bukti pembayaran yang sah.
                                    </div>
                                    <div>
                                        2. Mintalah invoice asli dan faktur pajak setiap kali akan melakukan pembayaran.
                                    </div>
                                    <div>
                                        3. Pembayaran tanpa disertai bukti yang sah merupakan tanggung jawab PELANGGAN sepenuhnya.
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
    name: "PrintPdf",
    data() {
        return {
            doIds: [],
            datas: [],
            timeNow: "",
            printDatetime: moment().format("DD.MM.YYYY HH:mm:ss"),
        };
    },
    mounted() {
        this.doIds = window.opener.doIds;
        if (this.doIds.length) {
            this.print();
        }
    },
    methods: {
        async print() {
            const tempTitle = document.title;
            await this.getData();
            await window.print();
            document.title = tempTitle;
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(
                `/api/wms/v1/simple-outbound-planner/surat-jalan2`,
                {
                    params: {
                        delivery_order_ids: this.doIds,
                        is_print: 1,
                    },
                }
            );
            if (resp.code == 200) {
                this.datas = resp.data;
                document.title = this.datas.reduce(
                    (prev, curr) => `${prev}-${curr.delivery_order_code}`,
                    "Surat_Jalan"
                );
                const now = new Date();
                this.timeNow = this.formatDate(now) + " " + this.formatTime(now);
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format("YYYY-MM-DD");
            };
        },
        formatTime: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format("hh:mm:ss");
            };
        },
        formatNumber: () => {
            return (val) => {
                if (val == null) {
                    return 0;
                }
                return Intl.NumberFormat("id", { minimumFractionDigits: 0 }).format(
                    val
                );
            };
        },
    },
};
</script>